import React from "react";

function agb() {
  return (
    <div className="container p-sm-4">
      <h3>LIEFERKONDITIONEN CREPES@HOME UND PUNSCH@HOME BOXEN</h3>
      <p>
        Der Lieferant liefert dem Kunden über die bestellten Speisen und Getränke hinaus, Gerätschaften und Behältnisse,
        um diese Getränke auszuschenken bzw. diese Speisen zuzubereiten. Die Speisen und Getränke werden mit den
        Gerätschaften und Behältnissen in Holzkisten ausgeliefert. Der Produktname „Crêpes@Home-Kochbox“ und
        „Crêpes@Home-Punschbox“ bezeichnet die Lieferung der Holzkiste(n) samt Inhalt als Gesamtheit.
      </p>
      <p>Die Lieferung der Holzkisten und Gerätschaften erfolgt leihweise.</p>
      <p>
        Bei Bestellung einer „Crêpes@Home-Kochbox“ oder „Crêpes@Home-Punschbox“ verpflichtet sich der Kunde, die
        Holzkisten und Gerätschaften in unbeschädigtem Zustand zurückzugeben. Die Abholung der Holzkisten und
        Gerätschaften erfolgt zum zwischen Kunden und Lieferanten vereinbarten Zeitpunkt am zwischen Kunden und
        Lieferanten vereinbarter Adresse.
      </p>
      <p>
        Wird eine oder mehrere der folgenden Gerätschaften oder Gegenstände nicht oder beschädigt zurückgegeben, werden
        dem Kunden folgende Beträge in Rechnung gestellt:
      </p>
      <div style={{ maxWidth: 245 + "px" }}>
        <ul>
          <li className="d-flex justify-content-between align-items-center">
            <span>Schöpfer</span>
            <span class="font-size-xs text-muted ml-3">€ 25.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Spachtel</span>
            <span class="font-size-xs text-muted ml-3">€ 25.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Crêpes-Holz</span>
            <span class="font-size-xs text-muted ml-3">€ 15.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Backpinsel</span>
            <span class="font-size-xs text-muted ml-3">€ 105.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Teigschüssel</span>
            <span class="font-size-xs text-muted ml-3">€ 20.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Gastro-Behälter</span>
            <span class="font-size-xs text-muted ml-3">€ 15.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Porzellan-Schüssel</span>
            <span class="font-size-xs text-muted ml-3">€ 5.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Häferl</span>
            <span class="font-size-xs text-muted ml-3">€ 4.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Weckglas klein</span>
            <span class="font-size-xs text-muted ml-3">€ 2.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Weckglas mittel</span>
            <span class="font-size-xs text-muted ml-3">€ 4.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Weckglas gross</span>
            <span class="font-size-xs text-muted ml-3">€ 6.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Kochschürze</span>
            <span class="font-size-xs text-muted ml-3">€ 15.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Holzkiste </span>
            <span class="font-size-xs text-muted ml-3">€ 20.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Isolierkanne 2 L</span>
            <span class="font-size-xs text-muted ml-3">€ 35.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Isolierkanne 3 L</span>
            <span class="font-size-xs text-muted ml-3">€ 40.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Isolierkanne 5 L</span>
            <span class="font-size-xs text-muted ml-3">€ 50.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Tropftasse</span>
            <span class="font-size-xs text-muted ml-3">€ 10.00</span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Isolierkanne 9 L</span>
            <span class="font-size-xs text-muted ml-3">€ 80.00</span>
          </li>
        </ul>
      </div>
      <p>Wird folgendes Gerät nicht zurückgegeben, wird folgender Betrag in Rechnung gestellt:</p>
      <p>
        Crêpes-Gerät € 700.00 Wird das Crêpes-Gerät beschädigt zurückgegeben, werden die Kosten der Reparatur durch eine
        Fachwerkstatt in Rechnung gestellt. Reparaturen durch den Ausfall von Verschleißteilen (zB Defekt der
        Heizwendel, des Thermostat usw.) werden nicht in Rechnung gestellt, die Kosten für hierfür trägt der Lieferant.
      </p>
      <h3>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h3>
      <h4>Vertrag</h4>
      <p>
        Durch die Aufgabe einer Bestellung schließt der Kunde einen Vertrag mit dem Lieferanten. Gegenstand dieses
        Vertrags ist die Zustellung von Getränken und Speisen an den Kunden, an eine durch den Kunden bekannt gegebene
        Adresse im Lieferradius des Lieferanten.
      </p>
      <p>
        Der Kunde ist an die Bestellung gebunden und hat keinen Anspruch auf eine Rückerstattung, außer im Falle einer
        Stornierung durch den Lieferanten.
      </p>
      <p>
        Der Vertrag gilt ab dem Zeitpunkt, an dem der Kunde die Bestellung aufgibt, indem er während des Bestellvorgangs
        auf der Plattform die Schaltfläche „Bestellen und bezahlen“ anklickt.
      </p>
      <p>Nach Eingang der Bestellung wird der Lieferant die Bestellung dem Kunden elektronisch bestätigen.</p>
      <p>
        Der Vertrag kann vom Lieferant nur dann ausgeführt werden, wenn der Kunde bei der Bestellung korrekte und
        vollständige Kontakt- und Adressinformationen zur Verfügung stellt. Der Kunde ist verpflichtet, unverzüglich
        alle Ungenauigkeiten in den Zahlungs- oder Lieferdaten zu melden, die an den Lieferanten übermittelt oder
        weitergegeben worden sind.
      </p>
      <p>
        In Bezug auf die Information betreffend des Status seiner Bestellung, muss der Kunde telefonisch oder per E-Mail
        (wie bei der Bestellung angegeben) für den Lieferanten erreichbar sein.
      </p>
      <p>
        Der Kunde muss zur angegebenen Lieferzeit an der angegebenen Lieferadresse anwesend sein, um die Lieferung der
        Bestellung entgegenzunehmen.
      </p>
      <p>
        Ist der Kunde zum Zeitpunkt der vereinbarten Lieferung nicht am gewünschten Lieferort anwesend und telefonisch
        nicht erreichbar, hat der Kunde keinen Anspruch auf Rückerstattung der Bestellung, noch auf eine
        Ersatzlieferung.
      </p>
      <p>
        Bei der Zustellung der Bestellung kann der Lieferant um eine Identifizierung bitten, wenn die Bestellung
        alkoholische Produkte enthält. Kann sich der Kunde nicht angemessen ausweisen oder erfüllt er nicht die
        Mindestaltersanforderungen, kann der Lieferant die Lieferung der entsprechenden Produkte an den Kunden
        verweigern. In diesem Fall hat der Kunde keinen Anspruch auf Rückerstattung der Bestellung, noch auf eine
        Ersatzlieferung.
      </p>
      <h4>Preise und Zahlung</h4>
      <p>
        Die auf der Plattform von den Anbietern angeführten Preise sind Endverbraucherpreise inklusive Umsatzsteuer,
        aber ohne Zustellkosten. An Zustellkosten fallen die im Zuge des Bestellvorgangs ausgewiesenen Kosten an.
      </p>
      <p>
        Eine Bezahlung des Kaufpreises ist mit den auf der Plattform vorgesehenen Methoden möglich. Der Kaufpreis ist
        sofort mit Annahme der Bestellung durch den Anbieter fällig. Somit wird der Betrag in Höhe des Kaufpreises
        entweder mit der Annahme der Bestellung durch den Anbieter von der vom Kunden angegebenen Kreditkarte,
        Paypalkonto oder Paybox/Handy abgebucht, oder per Sofortüberweisung.
      </p>
      <h4>Auflösung des Vertrags und Stornierung der Bestellung</h4>
      <p>Angesichts der Verderblichkeit des Angebots ist es dem Kunden nicht gestattet, den Vertrag aufzulösen. </p>
      <p>
        Das Restaurant ist berechtigt, die Bestellung zu stornieren, z.B. wenn das Angebot nicht mehr verfügbar ist oder
        wenn höhere Gewalt vorliegt. In diesen Fällen erfolgt eine Rückerstattung der Zahlung an den Kunden in der
        gesamten Höhe der Bestellung. Die Rückerstattung erfolgt immer auf das Konto, von dem aus die Zahlung
        vorgenommen wurde. Abhängig von der vom Kunden verwendeten Zahlungsmethode dauert die Bearbeitung der Erstattung
        maximal 10 Werktage. Darüber hinaus besteht für den Kunden kein Anspruch auf Schadenersatz, wenn die Bestellung
        durch den Lieferanten storniert wird. Wird die Bestellung verspätet geliefert, besteht kein Anspruch auf
        Rückerstattung des Kaufpreises, oder darüber hinaus gehenden Schadensersatz.
      </p>
      <h4>Beilegung von Beschwerden</h4>
      <p>Kundeseitige Beschwerden betreffend des Angebotes, der Bestellung oder der Ausführung</p>
      <p>
        Falls der Kunde eine Beschwerde bezüglich des Services hat, muss diese Beschwerde dem Kundendienstt des
        Lieferanten mittels des Kontaktformulars, per E-Mail oder auf dem normalen Postwege gemeldet werden.
      </p>
      <p>
        Sobald der Lieferant die Beschwerde erhalten hat, wird T dieser schnellstmöglich mit einer Empfangsbestätigung
        antworten. Der Lieferant ist bestrebt, die Beschwerde so bald wie möglich zu bearbeiten.
      </p>
      <p>
        Die Europäische Kommission stellt eine Plattform für die Online-Streitbeilegung bereit (OS-Plattform). Diese
        Plattform ist zu finden unter http://ec.europa.eu/odr . Der Lieferant schließt ausdrücklich eine alternative
        Streitbeilegung gemäß Richtlinie 2013/11/EU aus.
      </p>
      <h4>Schlussbestimmung</h4>
      <p>
        Anwendbares Recht und Gerichtsstand: Auf alle vertraglichen Beziehungen zwischen dem Kunden und dem Lieferanten
        ist ausschließlich Österreichisches Recht unter Ausschluss der Verweisungsnormen und des UN-Kaufrechts
        anwendbar. Ausschließlicher Gerichtsstand für alle Rechtstreitigkeiten ist das Bezirksgericht Ried/ Innkreis.
        Österreich.
      </p>
      <p>
        Schriftform, Erklärungen: Änderungen oder Ergänzungen des vorliegenden Vertrages sind nur wirksam, wenn sie
        schriftlich vereinbart wurden. Als Schriftform gilt ebenfalls E-Mail, SMS, MMS sowie Fax.
      </p>
      <p>
        Änderung der Nutzungsbedingungen: Der Lieferant behält sich das Recht vor, diese Nutzungsbedingungen
        einschließlich der Datenschutzbestimmungen jederzeit zu ändern. Solche Änderungen werden dem Nutzer – so er eine
        gültige E-Mail-Adresse bekannt gegeben hat – mit dem Wirksamkeitsdatum per E-Mail mitgeteilt. Die mitgeteilten
        Änderungen gelten als genehmigt, wenn der Nutzer nicht schriftlich oder per E-Mail innerhalb von vier Wochen ab
        Mitteilung der neuen Bedingungen Widerspruch erhebt. Auf die Wirkung eines Schweigens zu einer
        Änderungsmitteilung wird der Lieferant den Kunden in der E-Mail ausdrücklich hinweisen. Im Falle eines
        Widerspruchs des Kunden endet die Nutzungsberechtigung für die Plattform und wird ein etwaiger Kundenaccount
        gelöscht. Für nicht registrierte Kunden gelten die neuen Bedingungen ab Veröffentlichung auf der Plattform.
      </p>
      <p>
        Salvatorische Klausel: Sofern eine Bestimmung dieser Nutzungsbedingungen unwirksam ist, bleiben die übrigen
        Bestimmungen davon unberührt. Die unwirksame Bestimmung ist sodann durch eine Regelung zu ersetzen, die der
        wirtschaftlichen Zielsetzung der Parteien am nächsten kommt. Entsprechendes gilt, wenn diese Vertragsbedingungen
        Lücken enthalten.
      </p>
      <p>
        Speicherung des Vertragstextes: Im Rahmen des Vertragsschlusses erfolgt keine automatische Speicherung des
        Vertragstextes. Es gilt der jeweils aktuell gültige Text der Nutzungsbedingungen.
      </p>
    </div>
  );
}

export default agb;
